var exports = {};

/**
 * Slice reference.
 */
var slice = [].slice;
/**
 * Bind `obj` to `fn`.
 *
 * @param {Object} obj
 * @param {Function|String} fn or string
 * @return {Function}
 * @api public
 */

exports = function (obj, fn) {
  if ("string" == typeof fn) fn = obj[fn];
  if ("function" != typeof fn) throw new Error("bind() requires a function");
  var args = slice.call(arguments, 2);
  return function () {
    return fn.apply(obj, args.concat(slice.call(arguments)));
  };
};

export default exports;